<template lang="html">
  <v-app>
    <!-- <AppHeader /> -->
    <v-content>
      <!-- <router-view /> -->
      <HomePage />
    </v-content>
    <AppFooter />
  </v-app>
</template>

<script>
  //import AppHeader from "./components/AppHeader.vue";
  import AppFooter from "./components/AppFooter.vue";
  import HomePage from "./components/HomePage.vue";

  export default {
    name: "App",
    components: {
      //  AppHeader,
      HomePage,
      AppFooter,
    },
  };
</script>

<style>
  .v-application--wrap {
    min-height: auto;
  }
  .v-slide-group__content {
    justify-content: flex-end;
  }
</style>
