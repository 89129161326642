<template lang="html">
  <section class="home">
    <TopPage />
  </section>
</template>
<script>
import TopPage from "./TopPage.vue";

export default {
  components: {
    TopPage,
  },
};
</script>
<!-- <style lang="scss" scoped>
.home {
  padding-bottom: 50px;
}
</style> -->